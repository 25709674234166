import React, { Component } from 'react'

export default class Error extends Component {
  render() {
    const { content } = this.props

    return (
      <div {...this.props} hidden={!content}>
        {content && <div className="notification is-danger">
          {(content.data && content.data.error) || content.statusText || content}
        </div>}
      </div>
    )
  }
}
