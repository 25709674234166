import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { parse } from 'query-string'
import Axios from 'axios'

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      done: false
    }
  }

  componentDidMount() {
    let query = parse(this.props.location.search)
    sessionStorage.setItem('token', query.token)
    sessionStorage.setItem('cadToken', query['office-token']);
    Axios.defaults.headers.Authorization = query.token
    this.setState({ done: true })
  }

  render() {
    const { done } = this.state
    return (
      <div className="Login">
        <div className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-one-third">
                <progress className="progress is-large is-info" max="100" style={{ marginTop: 'calc(50vh - 24px)' }}>60%</progress>
              </div>
            </div>
          </div>
        </div>
        {done && <Redirect to="/dashboard" />}
      </div>
    )
  }
}
