import React, { Component, useRef } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Funnel from './pages/Funnel'

export default class App extends Component {
  render() {
    return (
      <div className="ba-app-content">
        <div style={{ height: '100%' }}>
          <HashRouter>
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/funnel/:id" component={Funnel} />
              <Route path="/login" component={Login} />
              <Route>
                <h1>404</h1>
              </Route>
            </Switch>
          </HashRouter>
        </div>
        {/*   <Overview /> */}
      </div>
    )
  }
}