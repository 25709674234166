import React, { Component } from 'react'
import { AreaChart, ResponsiveContainer, Tooltip, Area } from 'recharts'

export default class Statistics extends Component {
  render() {
    const { data } = this.props

    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        return (
          <div className="tag has-shadow">
            {label === 6 ? 'Today' : `${6 - label} days ago`}: {payload[0].value}
          </div>
        )
      }
      return null
    }

    const miniChart = (title, value, history) => (
      <div className="columns is-mobile is-vcentered">
        <div className="column">
          <b>{title}</b>
        </div>
        <div className="column has-text-centered">
          <small>{value}</small>
        </div>
        <div className="column is-half" style={{ height: 177 }}>
          <ResponsiveContainer>
            <AreaChart data={history.map(value => ({ value }))}>
              <Area type="monotone" dataKey="value" stroke={'#0294ff'} strokeWidth={2} fill="#0294ff" fillOpacity={0.25} dot={false} />
              <Tooltip content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    )

    return (
      <div className="Statistics">
        <div className="card">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-5">Statistics</p>
              </div>
            </div>
            {data && <div className="content">
              {miniChart('Clicks', data.RedirectCount, data.RedirectHistory.split(',').reverse())}
              {miniChart('Leads', data.LeadCount, data.LeadHistory.split(',').reverse())}
              {miniChart('Messages', data.MessageCount, data.MessageHistory.split(',').reverse())}
            </div>}
          </div>
        </div>
      </div>
    )
  }
}
