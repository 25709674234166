import React, { Component } from 'react'
import Axios from 'axios'
import Error from './Error'

export default class NewFunnel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      error: '',
      form: {
        BotID: 0,
        Name: ''
      },
      bots: []
    }
  }

  change(field, type = String) {
    return event => {
      let { form } = this.state
      form[field] = type(event.target.value)
      this.setState({ form })
    }
  }

  async toggleOpen() {
    try {
      this.setState({ loading: true })
      let { data: bots } = await Axios.get('/bot')
      this.setState({
        bots, open: true, loading: false, form: {
          BotID: 0,
          Name: ''
        }
      })
      setTimeout(() => this.refs.inputName.focus())
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  toggleClose() {
    this.setState({ open: false })
  }

  async save() {
    try {
      this.setState({ loading: true })
      let { form } = this.state
      await Axios.post('/funnel', form)
      if (this.props.onSave) {
        await this.props.onSave()
      }
      this.setState({ loading: false, open: false })
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  render() {
    const { open, loading, bots, form, error } = this.state

    return (
      <div className="NewFunnel is-inline-block has-text-left" style={this.props.style}>
        <button className={`button is-rounded is-primary is-square ${loading && 'is-loading'}`} onClick={this.toggleOpen.bind(this)}>
          <span className="icon">
            <i className="fas fa-plus"></i>
          </span>
        </button>
        <div className={`modal ${open && 'is-active'}`}>
          <div className="modal-background animated fadeIn faster" onClick={this.toggleClose.bind(this)}></div>
          <div className="modal-card animated slideInUp faster">
            <header className="modal-card-head">
              <span className="icon">
                <i className="fas fa-funnel-dollar"></i>
              </span>&nbsp;&nbsp;&nbsp;
              <p className="modal-card-title is-flex">New Funnel</p>
              <button className="delete" aria-label="close" onClick={this.toggleClose.bind(this)}></button>
            </header>
            <section className="modal-card-body">
              <div className="content">
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <label className="label">Name</label>
                      <div className="control">
                        <input className="input" type="text" placeholder="My Funnel" value={form.Name} onChange={this.change('Name')} disabled={loading} ref="inputName" />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label">Telegram Bot</label>
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select value={form.BotID} onChange={this.change('BotID', Number)} disabled={loading}>
                            <option value="">Select a Bot</option>
                            {bots.filter(b => !b.Error).map(b => (
                              <option value={b.ID} key={b.ID}>{b.Name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button className={`button is-primary is-rounded is-square ${loading && 'is-loading'}`} onClick={this.save.bind(this)} disabled={!form.Name || !form.BotID} data-ba-i="telegram_add_funnel">
                <span className="icon">
                  <i className="fas fa-save"></i>
                </span>
              </button>
              <button className="button is-light is-rounded is-square" onClick={this.toggleClose.bind(this)}>
                <span className="icon">
                  <i className="fas fa-undo-alt"></i>
                </span>
              </button>
            </footer>
          </div>
          <br />
          <Error className="modal-card has-text-left animated fadeIn faster" content={error} />
        </div>
      </div>
    )
  }
}
