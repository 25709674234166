import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { LineChart, Line, ResponsiveContainer } from 'recharts'
import Axios from 'axios'
import Bot from '../components/Bot'
import NewFunnel from '../components/NewFunnel'
import Error from '../components/Error'
import noResults from '../images/no-results.svg'

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      funnels: [],
      form: {
        filter: ''
      },
      loading: false,
      error: ''
    }
  }

  componentDidMount() {
    this.getFunnels()
  }

  change(field, type = String) {
    return event => {
      let { form } = this.state
      form[field] = type(event.target.value)
      this.setState({ form })
    }
  }

  async getFunnels() {
    try {
      this.setState({ loading: true, error: '' })
      let { data: funnels } = await Axios.get('/funnel')
      this.setState({ funnels, loading: false })
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }



  render() {

    const { funnels, form, loading, error } = this.state

    const filteredFunnels = funnels.filter(f => form.filter === '' || f.Name.indexOf(form.filter) >= 0)

    const miniChart = (title, value, history) => (
      <div className="columns is-mobile is-multiline" style={{ marginBottom: 0 }}>
        <div className="column">
          <b>{title}</b>
        </div>
        <div className="column has-text-centered">
          <small>{value}</small>
        </div>
        <div className="column">
          <ResponsiveContainer>
            <LineChart data={history.map(value => ({ value }))}>
              <Line type="monotone" dataKey="value" stroke={'#0294ff'} strokeWidth={2} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    )

    return (
      <div className="Dashboard animated fadeIn faster" style={{ paddingBottom: '10vh' }}>
        <div className="section">
          <div className="container">
            <div className="columns is-vcentered is-mobile is-multiline">
              <div className="column is-full-mobile has-text-centered-mobile">
                <h1 className="title">Telegram</h1>
                <h5 className="subtitle has-text-primary" style={{ fontSize: 10, marginLeft: '0.25rem' }}><b>AUTOMATION</b></h5>
              </div>
              <div className="column">
                <div className="field">
                  <p className="control has-icons-right">
                    <input className="input is-rounded" type="text" placeholder="Search" value={form.filter} onChange={this.change('filter')} />
                    <span className="icon is-right">
                      <i className="fas fa-search"></i>
                    </span>
                  </p>
                </div>
              </div>
              <div className="column is-one-quarter-mobile has-text-right">
                <Bot style={{ marginBottom: 8 }} />
                <NewFunnel onSave={this.getFunnels.bind(this)} style={{ marginLeft: 8 }} />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <Error className="modal-card has-text-left animated fadeIn faster" content={error} style={{ marginBottom: '3rem' }} />
            <div className="columns is-multiline">
              {filteredFunnels.map(f => (
                <div className="column is-one-quarter-fullhd is-one-third-widescreen is-half-desktop animated fadeIn faster" key={f.ID}>
                  <div className="card">
                    <div className="card-content">
                      <div className="media">
                        <div className="media-content">
                          <p className="title is-4">{f.Name.length > 16 ? `${f.Name.substring(0, 16)}...` : f.Name}</p>
                          <p className={`subtitle is-6 ${f.Bot.Error && 'has-line-through'}`}>@{f.Bot.Username.length > 24 ? `${f.Bot.Username.substring(0, 24)}...` : f.Bot.Username}</p>
                        </div>
                        <Link className="button is-small is-light is-rounded is-square" to={`/funnel/${f.ID}`}>
                          <span className="icon">
                            <i className="fas fa-pen"></i>
                          </span>
                        </Link>
                      </div>
                      <div className="content">
                        {miniChart('Clicks', f.Statistics.RedirectCount, f.Statistics.RedirectHistory.split(',').reverse())}
                        {miniChart('Leads', f.Statistics.LeadCount, f.Statistics.LeadHistory.split(',').reverse())}
                        {miniChart('Messages', f.Statistics.MessageCount, f.Statistics.MessageHistory.split(',').reverse())}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="has-text-centered" hidden={filteredFunnels.length || loading} style={{ marginTop: '20vh' }}>
              <img src={noResults} />
            </div>
            <div className="columns is-centered animated fadeIn" hidden={!loading} style={{ marginTop: '30vh' }}>
              <div className="column is-one-third">
                <progress className="progress is-small is-primary" max="100"></progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}