import React, { Component } from 'react'
import moment from 'moment'
import noResults from '../images/no-results.svg'
import Pagination from './Pagination'

export default class Leads extends Component {
  render() {
    const { list: leads, onChange } = this.props

    return (
      <div className="Leads">
        <div className="card">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-5">Leads</p>
              </div>
            </div>
            <div className="content" style={{ minHeight: 452 }}>
              <table className="table" hidden={!leads.List.length}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {leads.List.map(l => (
                    <tr key={l.ID}>
                      <td>{l.Name}</td>
                      <td>{moment(l.CreatedAt).fromNow()}</td>
                      <td className="has-text-right">
                        <a className="has-text-info is-link" href={`https://telegram.me/${l.Username}`} target="_blank" style={{ marginRight: '0.5rem' }} hidden={!l.Username}>
                          <i className="fas fa-comments"></i>
                        </a>
                        <span className="has-text-success is-link" hidden={l.Error}>
                          <i className="fas fa-check-circle"></i>
                        </span>
                        <span className="has-text-danger is-link" title={l.Error} hidden={!l.Error}>
                          <i className="fas fa-times-circle"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="has-text-centered" hidden={leads.List.length} style={{ transform: 'translateY(174px)' }}>
                <img src={noResults} />
              </div>
            </div>
            <div style={{ minHeight: 31 }}>
              <Pagination count={leads.Count} step={leads.Limit} current={leads.Page} onChange={onChange} hidden={!leads.List.length} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
