import React from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import './index.sass'
import App from './App'
import * as serviceWorker from './serviceWorker'

Axios.defaults.headers.Authorization = sessionStorage.getItem('token')

if (window.location.hostname === 'localhost') {
  Axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8080'
} else if (process.env.REACT_APP_API_URL) {
  Axios.defaults.baseURL = process.env.REACT_APP_API_URL
}

Axios.interceptors.response.use(response => response, error => {
  if (error.response && error.response.status === 401) {
    window.open('#/unauthorized', '_self')
  }
  return Promise.reject(error.response)
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
