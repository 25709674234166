import React, { Component } from 'react'
import Axios from 'axios'
import noResults from '../images/no-results.svg'
import Error from './Error'

export default class Bot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      create: false,
      error: '',
      bots: [],
      form: {
        Token: ''
      }
    }
  }

  change(field) {
    return event => {
      let { form } = this.state
      form[field] = event.target.value
      this.setState({ form })
    }
  }

  async toggleOpen() {
    try {
      this.setState({ loading: true })
      let { data: bots } = await Axios.get('/bot')
      this.setState({
        loading: false, open: true, bots, error: null, form: {
          Token: ''
        }
      })
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }

  toggleClose() {
    this.setState({ open: false, create: false })
  }

  toggleCreate(create) {
    return () => {
      this.setState({ create })
      if (create) {
        setTimeout(() => this.refs.inputToken.focus())
      }
    }
  }

  async create() {
    try {
      this.setState({ loading: true, error: '' })
      let { form } = this.state
      await Axios.post('/bot', form)
      let { data: bots } = await Axios.get('/bot')
      this.setState({ loading: false, create: false, bots })
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }

  render() {
    const { open, loading, bots, create, form, error } = this.state

    return (
      <div className="Bot is-inline-block" style={this.props.style}>
        <button className={`button is-rounded is-primary is-square ${loading && 'is-loading'}`} onClick={this.toggleOpen.bind(this)}>
          <span className="icon">
            <i className="fas fa-robot"></i>
          </span>
        </button>
        <div className={`modal ${open && 'is-active'}`}>
          <div className="modal-background animated fadeIn faster" onClick={this.toggleClose.bind(this)}></div>
          <div className="modal-card animated slideInUp faster">
            <header className="modal-card-head">
              <span className="icon">
                <i className="fas fa-robot"></i>
              </span>&nbsp;&nbsp;&nbsp;
              <p className="modal-card-title is-flex">My Robots</p>
              <button className="delete" aria-label="close" onClick={this.toggleClose.bind(this)}></button>
            </header>
            <section className="modal-card-body">
              <div className="content" hidden={!bots.length}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bots.map(b => (
                      <tr key={b.ID}>
                        <td>{b.ID}</td>
                        <td>{b.Name}</td>
                        <td>{b.Username}</td>
                        <td className="has-text-right">
                          <span className="has-text-success is-link" hidden={b.Error}>
                            <i className="fas fa-check-circle"></i>
                          </span>
                          <span className="has-text-danger is-link" title={b.Error} hidden={!b.Error}>
                            <i className="fas fa-times-circle"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="has-text-centered" hidden={bots.length}>
                <img src={noResults} />
              </div>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-primary is-rounded is-square animated fadeIn faster" onClick={this.toggleCreate(true)} hidden={create}>
                <span className="icon">
                  <i className="fas fa-plus"></i>
                </span>
              </button>
              <small style={{ flex: 1 }} hidden={create}>
                <a href="https://telegram.me/botfather" target="_blank">@BotFather</a>
              </small>
              <input className="input is-rounded animated fadeIn faster" type="text" placeholder="Telegram Bot Token" value={form.Token} onChange={this.change('Token')} hidden={!create} style={{ marginRight: '1rem' }} disabled={loading} ref="inputToken" />
              <button className={`button is-primary is-rounded is-square animated fadeInLeft faster ${loading && 'is-loading'}`} onClick={this.create.bind(this)} hidden={!create} disabled={!form.Token} data-ba-i="telegram_add_bot">
                <span className="icon">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </button>
              <button className="button is-light is-rounded is-square animated fadeInLeft faster" onClick={this.toggleCreate(false)} hidden={!create} disabled={loading}>
                <span className="icon">
                  <i className="fas fa-undo-alt"></i>
                </span>
              </button>
            </footer>
          </div>
          <br />
          <Error className="modal-card has-text-left animated fadeIn faster" content={error} />
        </div>
      </div>
    )
  }
}
