import React, { Component } from 'react'
import vis from 'vis-network'
import './Schedules.sass'
import Axios from 'axios'
import Error from './Error'

export default class Schedules extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      selectedID: 0,
      error: '',
      openEdit: false,
      form: {
        Delay: 0,
        Message: '',
        Condition: 'any'
      },
      schedules: [],
      openRemove: false
    }
    this.data = {
      nodes: new vis.DataSet([]),
      edges: new vis.DataSet([])
    }
    this.network = null
  }

  async componentDidMount() {
    this.network = new vis.Network(this.refs.container, this.data, options)
    this.network.on('click', e => {
      let [selectedID] = e.nodes
      if (!selectedID) this.network.unselectAll()
      this.setState({ selectedID })
    })
    this.network.on('dragStart', e => {
      let [selectedID] = e.nodes
      if (!selectedID) this.network.unselectAll()
      this.setState({ selectedID })
    })

    const { funnelID } = this.props
    let { data: schedules } = await Axios.get(`/schedule?funnel=${funnelID}`)
    this.setState({ schedules })
    this.redraw()
  }

  change(field, type = String) {
    return event => {
      let { form } = this.state
      form[field] = type(event.target.value)
      this.setState({ form })
    }
  }

  edit() {
    let { selectedID, schedules } = this.state
    let form = schedules.find(s => s.ID === selectedID)
    this.setState({ openEdit: true, form, error: '' })
  }

  create() {
    let { selectedID } = this.state
    this.setState({
      form: {
        ScheduleID: selectedID || null,
        Delay: 0,
        Message: '',
        Condition: 'any'
      },
      openEdit: true
    })
  }

  close() {
    this.setState({ openEdit: false })
  }

  async save() {
    try {
      this.setState({ loading: true, error: '' })
      const { funnelID } = this.props
      let { form } = this.state
      form.FunnelID = Number(funnelID)
      if (form.ID) {
        await Axios.patch('/schedule', form)
      } else {
        await Axios.post('/schedule', form)
      }
      let { data: schedules } = await Axios.get(`/schedule?funnel=${funnelID}`)
      this.setState({ schedules, openEdit: false, loading: false })
      this.redraw()
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  redraw() {
    this.data.nodes.forEach(n => this.data.nodes.remove(n.id))
    this.data.edges.forEach(e => this.data.edges.remove(e.id))

    const { schedules } = this.state


    schedules.forEach(s => {
      let label = `${s.LeadCount}`
      let level = 0
      let image = require('../images/mail-funnel.png')

      if (s.ID) {
        let lastLeadCount = schedules.find(s2 => s2.ID == (s.ScheduleID || 0)).LeadCount || 1

        label = `${label} (${Math.floor((s.LeadCount / lastLeadCount) * 100)}%)`
        level = this.level(schedules, s)
        image = s.Delay ? require('../images/mail-time.png') : require('../images/mail-direct.png')
      }

      this.data.nodes.add({ id: s.ID, level, image, label })

      if (s.ID) {
        this.data.edges.add({
          from: s.ID,
          to: s.ScheduleID || 0,
          ...this.edgeStyle(s)
        })
      }
    })

    this.network.redraw()
  }

  level(schedules, schedule, count = 1) {
    let father = schedules.find(s => s.ID === schedule.ScheduleID)
    if (father) {
      return this.level(schedules, father, ++count)
    }
    return count
  }

  edgeStyle(s) {
    switch (s.Condition) {
      case 'link_true':
        return { label: '✔', font: { color: '#48d2a0' } }
      case 'link_false':
        return { label: '✘', font: { color: '#f54b5e' } }
      default:
        return {}
    }
  }

  openRemove() {
    this.setState({ openRemove: true, error: '' })
  }

  closeRemove() {
    this.setState({ openRemove: false, error: '' })
  }

  async remove() {
    try {
      this.setState({ loading: true, error: '' })
      const { funnelID } = this.props
      let { form } = this.state
      await Axios.delete(`/schedule/${form.ID}`)
      let { data: schedules } = await Axios.get(`/schedule?funnel=${funnelID}`)
      this.setState({ schedules, openEdit: false, loading: false, openRemove: false })
      this.redraw()
    } catch (error) {
      this.setState({ error, loading: false, openRemove: false })
    }
  }

  render() {
    const { loading, openEdit, selectedID, form, openRemove, error } = this.state

    return (
      <div className="Schedules">
        <div className="card">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-5">Schedule</p>
              </div>
              <button className="button is-small is-light is-rounded is-square" onClick={this.edit.bind(this)} hidden={!selectedID}>
                <span className="icon">
                  <i className="fas fa-pen"></i>
                </span>
              </button>&nbsp;&nbsp;
              <button className="button is-small is-light is-rounded is-square" onClick={this.create.bind(this)}>
                <span className="icon">
                  <i className="fas fa-plus"></i>
                </span>
              </button>
            </div>
            <div className="content vis-container">
              <div className="vis-network" ref="container" />
            </div>
          </div>
        </div>
        <div className={`modal ${openEdit && 'is-active'}`}>
          <div className="modal-background animated fadeIn faster" onClick={this.close.bind(this)}></div>
          <div className="modal-card animated slideInUp faster">
            <header className="modal-card-head">
              <p className="modal-card-title">Edit Message</p>
              <button className="delete" aria-label="close" onClick={this.close.bind(this)}></button>
            </header>
            <section className="modal-card-body">
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Condition</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select value={form.Condition} onChange={this.change('Condition')}>
                          <option value="any">Any</option>
                          <option value="link_true">Clicked link</option>
                          <option value="link_false">Unclicked link</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Delay in minutes</label>
                    <div className="control">
                      <input className="input" type="number" placeholder="Time in minutes" value={form.Delay} onChange={this.change('Delay', Number)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea className="textarea" placeholder="Type the message" value={form.Message} onChange={this.change('Message')}></textarea>
                </div>
                <p className="help">To use lead name <code>$NAME</code></p>
              </div>
            </section>
            <footer className="modal-card-foot" hidden={openRemove}>
              <button className={`button is-primary is-rounded is-square ${loading && 'is-loading'}`} onClick={this.save.bind(this)}>
                <span className="icon">
                  <i className="fas fa-save"></i>
                </span>
              </button>
              <button className="button is-danger is-rounded is-square" onClick={this.openRemove.bind(this)}>
                <span className="icon">
                  <i className="far fa-trash-alt"></i>
                </span>
              </button>
              <button className="button is-light is-rounded is-square" onClick={this.close.bind(this)}>
                <span className="icon">
                  <i className="fas fa-undo-alt"></i>
                </span>
              </button>
            </footer>
            <footer className="modal-card-foot" hidden={!openRemove}>
              <span className="remove-label">Are you sure you want to remove?</span>
              <button className={`button is-danger is-rounded is-square ${loading && 'is-loading'}`} onClick={this.remove.bind(this)}>
                <span className="icon">
                  <i className="fas fa-check"></i>
                </span>
              </button>
              <button className="button is-light is-rounded is-square" onClick={this.closeRemove.bind(this)}>
                <span className="icon">
                  <i className="fas fa-undo-alt"></i>
                </span>
              </button>
            </footer>
          </div>
          <br />
          <Error className="modal-card has-text-left animated fadeIn faster" content={error} />
        </div>
      </div>
    )
  }
}

const options = {
  autoResize: true,
  nodes: {
    shape: 'image',
    shadow: {
      enabled: true,
      x: 0,
      y: 3,
      size: 6,
      color: 'rgba(0,0,0,0.16)'
    },
    margin: 200,
    font: {
      face: 'Muli,sans-serif',
      size: 12,
      color: '#4a4a4a'
    }
  },
  edges: {
    color: {
      color: '#4a4a4a',
      highlight: '#0294ff',
      hover: '#4a4a4a'
    },
    width: 1.75
  },
  layout: {
    hierarchical: {
      enabled: true,
      levelSeparation: 120,
      nodeSpacing: 120,
      treeSpacing: 120,
      direction: window.innerWidth <= 425 ? 'UD' : 'LR'
    }
  },
  physics: {
    enabled: false
  }
}
