import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Schedules from '../components/Schedules'
import Leads from '../components/Leads'
import Statistics from '../components/Statistics'
import Error from '../components/Error'

export default class Funnel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      funnel: {},
      leads: { List: [], Count: 0, Page: 1 },
      funnelLink: '',
      loading: false
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      let { match: { params } } = this.props
      let [{ data: funnel }, { data: leads }] = await Promise.all([
        Axios.get(`/funnel/${params.id}`),
        Axios.get(`/lead?funnel=${params.id}&page=1&limit=10`)
      ])
      let funnelLink = `${window.origin}/f/${Number(funnel.ID).toString(36)}`
      this.setState({ funnel, funnelLink, leads, loading: false })
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  async leads(page = 1) {
    let { match: { params } } = this.props
    let { data: leads } = await Axios.get(`/lead?funnel=${params.id}&page=${page}&limit=10`)
    this.setState({ leads })
  }

  copy(text) {
    return () => navigator.clipboard.writeText(text)
  }

  render() {
    const { funnel, leads, funnelLink, loading, error } = this.state
    const { match: { params: { id: funnelID } } } = this.props

    return (
      <div className="Funnel animated fadeIn faster" style={{ paddingBottom: '15%' }}>
        <div className="section animated fadeIn faster" hidden={loading}>
          <div className="container">
            <div className="columns is-vcentered is-mobile is-multiline">
              <div className="column is-1-tablet is-3-mobile">
                <Link className="button is-light is-rounded is-square animated fadeInRight fast" to="/dashboard">
                  <span className="icon">
                    <i className="fas fa-arrow-left"></i>
                  </span>
                </Link>
              </div>
              <div className="column has-text-right-mobile">
                <h1 className="title">{funnel.Name}</h1>
              </div>
              <div className="column is-full-mobile has-text-right">
                <button className="button is-small is-rounded is-light is-family-monospace" onClick={this.copy(funnelLink)}>
                  {funnelLink}&nbsp;&nbsp;
                  <span className="icon">
                    <i className="fas fa-link"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Error className="container animated fadeIn faster" content={error} />
        <div className="section animated fadeIn faster" hidden={loading}>
          <div className="container">
            <div className="columns">
              <div className="column is-half">
                <Statistics data={funnel.Statistics} />
              </div>
              <div className="column is-half">
                <Leads list={leads} onChange={this.leads.bind(this)} />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Schedules funnelID={funnelID} />
              </div>
            </div>
          </div>
        </div>
        <div className="container animated fadeIn" hidden={!loading} style={{ marginTop: '50vh' }}>
          <div className="columns is-centered">
            <div className="column is-one-third">
              <progress className="progress is-small is-primary" max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
